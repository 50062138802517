import axios from "axios"
import { BASE_URL } from "../../constants/constants"


/**
 * Get api with filter queries
 * @param {Object} params
 * @param {string} path
 * @returns {Promise}
 */
const filterFetch = (params, path) => {
    return axios.get(
        BASE_URL + path,
        {
            params
        }
    )
}

/**
 * Get api
 * @param {string} path
 * @returns {Promise}
 */
const normalFetch = (path) => {

    return axios.get(BASE_URL + path)
}


export {
    filterFetch,
    normalFetch
}
