import { CloseIcon, DText, Flex } from "../../../../styles/components/common/common-styles";
import {
    C2cNext,
    ModificationHeader,
    ModificationItemBody
} from "../../../../styles/components/c2c";
import Text from "../../../../core/utils/Text";
import CheckBox from "../../../common/input/CheckBox";
import { useEffect, useRef, useState } from "react";
import { deepCopy } from "../../../../core/utils/common";
import {
    MAddressReplace,
    MAddWord,
    MBoycott,
    MDeleteWord,
    MLink, MOptions,
    MReplaceWord,
    MTemplate, MWhiteList
} from "./ModificationModes";
import { IoIosCopy } from 'react-icons/io'
import { FaPaste } from 'react-icons/fa'
import styled from "styled-components";
import { useQueryContext } from "../../../../core/contexts/query";


const initialModifications = [
    { area: 'a', active: false, values: { kind: 'template', text: false, audio: false, video: false, pic: false, file: false } },
    { area: 'b', active: false, values: { kind: 'link', link: '', mentionCount: 0, options: { withoutLink: false, haveOtherLinks: false, noUrl: false, manyMentions: false, boycottUrl: false } } },
    { area: 'c', active: false, values: { kind: 'add-word', words: [{ placement: 'start', word: '' }, { placement: 'end', word: '' }] } },
    { area: 'd', active: false, values: { kind: 'boycott', words: [] } },
    { area: 'e', active: false, values: { kind: 'delete-word', search: [] } },
    { area: 'f', active: false, values: { kind: 'address-replace', search: '', replace: '' } },
    { area: 'g', active: false, values: { kind: 'replace-word', search: [], replace: [] } },
    { area: 'h', active: false, values: { kind: 'white-list', words: [] } },
    { area: 'i', active: false, values: { kind: 'option', manySpaces: 0, spaceChange: 0, manyEnters: 0, enterChange: 0 } },
]

const modificationItems = {
    template: MTemplate,
    link: MLink,
    'add-word': MAddWord,
    boycott: MBoycott,
    'delete-word': MDeleteWord,
    'address-replace': MAddressReplace,
    'replace-word': MReplaceWord,
    'white-list': MWhiteList,
    'option': MOptions
}

const Modification = ({ modification, onSave, onClose, disable }) => {

    const { setToast } = useQueryContext()
    const [modifications, setModifications] = useState(initialModifications)

    useEffect(() => {
        try {
            const newModifications = deepCopy(modifications)
            for (const mod of modification) {
                const index = newModifications.findIndex(i => i.values.kind === mod.kind)
                if (index < 0) continue
                newModifications[index].values = mod
                newModifications[index].active = true
            }
            setModifications(newModifications)
        }
        catch (err) { }

    }, [modification])

    const onChangeStatus = (idx) => {
        const newState = deepCopy(modifications)
        newState[idx].active = !newState[idx].active
        setModifications(newState)
    }

    const onModificationChange = (kind, newValues) => {
        const idx = modifications.findIndex(x => x.values.kind === kind)
        const newModifications = deepCopy(modifications)
        newModifications[idx].values = newValues
        setModifications(newModifications)
    }

    const onCopy = () => {
        navigator.clipboard.writeText(JSON.stringify(modifications))
        setToast({
            message: 'copy-success', show: true
        })
    }
    const onPaste = async () => {
        const clipboardContents = await navigator.clipboard.read()
        for (const item of clipboardContents) {
            if (item.types.includes("text/plain")) {
                const blob = await item.getType("text/plain")
                const txt = await blob.text()
                if (typeof JSON.parse(txt) === 'object') {
                    setModifications(JSON.parse(txt))
                }
            }
        }
    }

    const dragItem = useRef(null)
    const dragOver = useRef(null)
    const handleSort = () => {
        let newMods = deepCopy(modifications)
        const draggedItem = newMods.splice(dragItem.current, 1)[0]
        newMods.splice(dragOver.current, 0, draggedItem)
        dragItem.current = null
        dragOver.current = null
        setModifications(newMods)
    }

    return (
        <>
            <div style={{ width: '100%', height: 'calc(100vh - 144px)', overflowY: 'auto' }}>
                <Flex width={'calc(100% - 40px)'} style={{ position: 'fixed', top: '20px' }} justify={'space-between'}>
                    <Flex>
                        <CP size={18} style={{ margin: '0 10px' }} onClick={onCopy} />
                        <PA size={18} style={{ margin: '0 10px' }} onClick={onPaste} />
                    </Flex>
                    <CloseIcon size={18} onClick={onClose} />
                </Flex>
                <div className={'grid grid-cols-2'}>
                    {modifications.map((item, idx) => {

                        const { area, values: { kind } } = item
                        const Child = modificationItems[kind]

                        return (
                            <Wrapper>
                                <ModificationItem
                                    area={area}
                                    item={item}
                                    onChangeStatus={() => onChangeStatus(idx)}
                                    disable={disable}
                                    number={idx + 1}
                                    draggable
                                    onDragStart={() => dragItem.current = idx}
                                    onDragEnter={() => dragOver.current = idx}
                                    onDragEnd={handleSort}
                                >
                                    <ModificationItemBody active={item.active}>
                                        <Child
                                            item={item.values}
                                            onChange={onModificationChange}
                                            disable={disable}
                                        />
                                    </ModificationItemBody>
                                </ModificationItem>
                            </Wrapper>
                        )
                    })}
                </div>
            </div>
            <Flex width={'98%'} justify={'flex-end'}>
                <C2cNext onClick={() => {
                    disable ? onClose() : onSave(modifications)
                }}>
                    <Text tid={disable ? 'close' : 'save'} />
                </C2cNext>
            </Flex>
        </>
    )
}

const ModificationItem = ({ area, item, children, onChangeStatus, disable, number, onDragStart, onDragEnd, onDragEnter }) => {

    const _onChangeStatus = () => !disable && onChangeStatus()
    return (
        <div>
            <ModificationHeader
                color={colors[area]}
                draggable
                onDragStart={onDragStart}
                onDragEnd={onDragEnd}
                onDragEnter={onDragEnter}
            >
                <span className={'text-sm'}>{number}-</span>
                <CheckBox dark active={item.active} onClick={_onChangeStatus} />
                <DText style={{ margin: '0 8px' }}>
                    <Text tid={`m-${item.values.kind}`} />
                </DText>
            </ModificationHeader>
            {children}
        </div>
    )
}

const colors = {
    a: '#819F82', b: '#B29AC8',
    c: '#9F9181', d: '#9CD6E4',
    e: '#C7DAA4', f: '#C8B99A',
    g: '#9F819E', h: '#818F9F',
    i: '#819F82'
}

const CP = styled(IoIosCopy)`
  color: ${props => props.theme.color};
  cursor: pointer;
`
const PA = styled(FaPaste)`
  color: ${props => props.theme.color};
  cursor: pointer;
`

const Wrapper = styled.div`
  border-radius: 8px;
  background-color: ${props => props.theme.secondaryBg};
  padding: 8px 16px;
`

export default Modification
