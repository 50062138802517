import {
    useDeleteGroupMutation, useGetGroupsByCategories, useRefreshGroups,
} from "../../../core/services/react-query/group";
import ModalLayout from "../../layouts/main/ModalLayout";
import CreateGroup from "./CreateGroup";
import GroupList from "./GroupList";
import TableLayout from "../../layouts/main/TableLayout";
import ChatRefresher from "../chat/ChatRefresher";
import GroupAccessRefresher from "./GroupAccessRefresher";
import GroupStats from "./GroupStats";
import { useMainContext } from "../../../core/contexts/main";
import { DText, IconWrapper } from "../../../styles/components/common/common-styles";
import { FaDownload } from "react-icons/fa";
import { useDownloadPdf } from "../../common/pdf/pdf";
import MultiSelect from "../../common/select/MultiSelect";
import { useState } from "react";
import { useGetCategoryType } from "../../../core/services/react-query/category";
import Text from "../../../core/utils/Text";
import ActionButton from "../../common/buttons/ActionButton";
import ButtonLoader from "../../common/loading/ButtonLoader";
import GroupPdf from "../../common/pdf/GroupPdf";


const GroupComp = ({
    modal,
    data
}) => {

    const { main: { lang } } = useMainContext()

    const { createModal, setCreateModal } = modal
    const { data: groups } = data

    const { mutate: deleteGroup } = useDeleteGroupMutation()

    const onCreateGroupSuccess = () => {
        setCreateModal(false)
    }


    const [selectedCategories, setSelectedCategories] = useState([])
    const { data: categories, isLoading: loadingGroupByCategoriesList } = useGetCategoryType('group')
    const { data: pdfGroups, refetch: getGroupsByCategories } = useGetGroupsByCategories({
        categoryIds: selectedCategories?.map(s => s._id) || []
    })
    const [chooseCategoriesModal, setChooseCategoriesModal] = useState(false)

    const {
        pdfModal,
        openPdfModal,
        pdfRef,
        step,
        onDownloadClick,
        makingPngs,
        onCancel
    } = useDownloadPdf({
        pdfName: 'گزارش زیر ساخت کلاف',
        listLength: pdfGroups?.length
    })

    const onReadyPdfDownloader = () => {
        getGroupsByCategories()
        openPdfModal()
        setChooseCategoriesModal(false)
    }

    return (
        <>
            <GroupStats stats={data?.data?.stats} />
            <TableLayout
                data={data}
                headers={headers}
                cs={CS}
            >
                <GroupList
                    groups={groups}
                    cs={CS}
                    deleteGroup={deleteGroup}
                />
                <div className={`absolute top-[40px] ${lang === 'en' ? 'right-[40px]' : 'left-[40px]'} flex items-center gap-2`}>
                    <IconWrapper onClick={() => setChooseCategoriesModal(true)}>
                        <FaDownload color={'#ffc800'} size={16} />
                    </IconWrapper>
                    <GroupAccessRefresher
                        countUpdateType={'Groups'}
                    />
                    <ChatRefresher
                        query={useRefreshGroups}
                        type={'group'}
                        countUpdateType={'Groups'}
                    />
                </div>
            </TableLayout>

            <ModalLayout
                open={chooseCategoriesModal}
                onClose={() => setChooseCategoriesModal(false)}
                width='500px'
            >
                <div className={'flex flex-col gap-5'}>
                    <DText primary>
                        <Text tid={'choose-categories-group-list'} />
                    </DText>
                    <MultiSelect
                        values={selectedCategories}
                        options={categories || []}
                        type={'category'}
                        param={'name'}
                        placeHolder={`choose-categories`}
                        onValuesChange={(selectedOptions) => setSelectedCategories(selectedOptions)}
                    />
                    <ActionButton
                        active
                        onClick={onReadyPdfDownloader}
                    >
                        <ButtonLoader
                            tid='submit'
                            loading={loadingGroupByCategoriesList}
                        />
                    </ActionButton>
                </div>
            </ModalLayout>


            <ModalLayout
                isStatic
                open={pdfModal}
                width={'800px'}
            >
                <GroupPdf
                    ref={pdfRef}
                    step={step}
                    onCancel={onCancel}
                    onDownloadClick={onDownloadClick}
                    makingPngs={makingPngs}
                    groups={pdfGroups || []}
                    selectedCategories={selectedCategories}
                />
            </ModalLayout>

            <ModalLayout
                open={createModal}
                onClose={() => setCreateModal(false)}
                width='500px'
            >
                <CreateGroup
                    onCreateGroupSuccess={onCreateGroupSuccess}
                    onClose={() => setCreateModal(false)}
                />
            </ModalLayout>
        </>
    )
}

const CS = '10% 18% 18% 10% 10% 8% 15% 10%'
const headers = [
    'title', 'address', 'subscribers', 'online', 'type', 'modified'
]

export default GroupComp
