import { MAWrapper, C2cIconWrapper } from "../../../../styles/components/c2c";
import { DeleteIcon, DText, Flex } from "../../../../styles/components/common/common-styles";
import { FaTelegramPlane } from 'react-icons/fa'
import { useSendModeratorMessage } from "../../../../core/services/react-query/c2c";
import { ScaleLoader } from "react-spinners";
import { useMainContext } from "../../../../core/contexts/main";
import Dropdown from "../../../modals/Dropdown";
import { useEffect, useState } from "react";
import mimeDb from '../../../../assets/mime/mimeDb.json'


const ModeratorActions = (props) => {

    const { post, message, index, onSendMessage } = props
    const { main: { lang } } = useMainContext()
    const [moreOptions, setMoreOptions] = useState([])
    useEffect(() => {
        if (!!post) {
            let more = []
            if (post.telegramBridge) more.push('telegramBridge')
            if (post.eitaaBridge) more.push('eitaaBridge')
            setMoreOptions(more)
        }
    }, [post])


    const { mutate: sendMessage, isLoading } = useSendModeratorMessage(onSendMessage)

    const onAction = (type) => {

        let files = []
        post.name.forEach((name, idx) => {
            try {
                const mime = post.mime[idx]
                const ext = mimeDb[mime].extensions[0]
                files.push(`${name}.${ext}`)
            }
            catch (_) { }
        })

        const payload = {
            sim_id: post.sim_id,
            c2c_post_id: post._id,
            message_id: post.messageId,
            options: {
                telegram: type === 'telegram' || type === 'both',
                eitaa: type === 'eitaa' || type === 'both',
                telegramBridge: type === 'telegramBridge',
                eitaaBridge: type === 'eitaaBridge'
            },
            caption: message,
            files,
            lmId: post.lmId
        }
        sendMessage(payload)
    }

    const HAS_TEL = !!post?.destination && post?.message?.telegram
    const HAS_EIT = post?.hasEitaa && post?.message?.eitaa
    const HAS_BOTH = HAS_TEL && HAS_EIT

    return (
        <Flex justify={`flex-${lang === 'en' ? 'end' : 'start'}`}>
            <MAWrapper className={'relative'}>
                <div className={`absolute right-1 bottom-1`}>
                    <DText main fontSize={'s'}>
                        {index}.
                    </DText>
                </div>
                <Flex fw fh>
                    {isLoading ?
                        <ScaleLoader
                            color={'#3B6978'}
                            height={24}
                            width={2}
                        />
                        :
                        <Flex fw fh justify={'space-around'}>
                            {HAS_TEL ?
                                <TelegramIcon onClick={() => onAction('telegram')} />
                                : null}
                            {HAS_EIT ?
                                <EitaaIcon onClick={() => onAction('eitaa')} />
                                : null}
                            {HAS_BOTH ?
                                <Both onClick={() => onAction('both')} />
                                : null}
                            <Delete onClick={() => onAction('delete')} />

                            {moreOptions.length ?
                                <Dropdown
                                    options={moreOptions}
                                    onOptionClicked={(idx) => onAction(moreOptions[idx])}
                                    top={'20px'}
                                    style={{ minHeight: `${moreOptions.length * 80}px` }}
                                />
                                : null}
                        </Flex>
                    }
                </Flex>
            </MAWrapper>
        </Flex>

    )
}

const TelegramIcon = (props) => {

    return (
        <C2cIconWrapper {...props}>
            <FaTelegramPlane
                color={'#33afed'}
                size={20}
            />
        </C2cIconWrapper>
    )
}

const EitaaIcon = (props) => {

    return (
        <C2cIconWrapper {...props}>
            <img
                src={require('../../../../assets/images/eitaa.png')}
                width={'28px'}
                alt={'telegram'}
            />
        </C2cIconWrapper>
    )
}

const Both = (props) => {

    return (
        <C2cIconWrapper {...props} style={{ width: '56px', height: '32px', borderRadius: '4px' }}>
            <Flex fw fh>
                <FaTelegramPlane
                    color={'#33afed'}
                    size={20}
                />
                <img
                    src={require('../../../../assets/images/eitaa.png')}
                    width={'26px'}
                    alt={'telegram'}
                />
            </Flex>
        </C2cIconWrapper>

    )
}


const Delete = (props) => {

    return (
        <C2cIconWrapper {...props}>
            <DeleteIcon color={'#e9104a'} size={20} />
        </C2cIconWrapper>
    )
}


export default ModeratorActions
